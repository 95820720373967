.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 10px 7px 10px 7px;
  cursor: default;
}
.title-line {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.title-line-serial {
  display: grid;
  grid-template-columns: auto auto 1fr;
  cursor: pointer;
}
.title-text-bold {
  font-weight: bold;
}
.tree-scrollable {
  height: calc(100% - 82px);
  background-color: #ffff;
  overflow: auto;
}
